require("normalize.css/normalize.css");
require("swagger-ui/dist/swagger-ui.css");
require("swagger-ui-themes/themes/3.x/theme-muted.css");
require("../css/main.css");

const SwaggerUI = require("swagger-ui");
const SwaggerUIStandalonePreset = require("swagger-ui/dist/swagger-ui-standalone-preset");

const ui = SwaggerUI({
  urls: [
    {
      name: "Relatiebeheer",
      url: "api/taskgroups.json",
    },
    {
      name: "Leegstand",
      url: "api/vacancy.json",
    },
    {
      name: "Room",
      url: "api/room.json",
    },
    {
      name: "Floor",
      url: "api/floor.json",
    },
    {
      name: "Sensor",
      url: "api/sensor.json",
    },
  ],
  dom_id: "#swagger",
  deepLinking: true,
  presets: [SwaggerUI.presets.apis, SwaggerUIStandalonePreset],
  plugins: [SwaggerUI.plugins.DownloadUrl],
  layout: "StandaloneLayout",
});

global.ui = ui;
